import React from "react";

import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaEnvelope,
  FaWhatsapp,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className=" w-full mt-24 bg-slate-900 text-gray-300 py-y px-2">
      <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8">
        <div>
          <h6 className="font-bold uppercase pt-2">Compañía</h6>
          <ul>
            <li className="py-1">Servicios</li>
            <li className="py-1">Catálogos</li>
            <li className="py-1">Tecnologías</li>
          </ul>
        </div>
        <div>
          <h6 className="font-bold uppercase pt-2">Servicios</h6>
          <ul>
            <li className="py-1">Instalación</li>
            <li className="py-1">Capacitación</li>
            <li className="py-1">Mantenimiento</li>
            <li className="py-1">Calibración</li>
          </ul>
        </div>
        <div>
          <h6 className="font-bold uppercase pt-2">Tecnologías</h6>
          <ul>
            <li className="py-1">Software</li>
            <li className="py-1">Metrología</li>
            <li className="py-1">Ingeniería inversa</li>
            <li className="py-1">Scanner Laser 3D</li>
            <li className="py-1">CMM</li>
            <li className="py-1">Rayos X/Tomografía NDT</li>
          </ul>
        </div>
        {/*
        <div>
          <h6 className="font-bold uppercase pt-2">Legal</h6>
          <ul>
            <li className="py-1">Claims</li>
            <li className="py-1">Privacy</li>
            <li className="py-1">Terms</li>
            <li className="py-1">Policies</li>
            <li className="py-1">Conditions</li>
          </ul>
        </div>
        */}
        <div className="col-span-2 pt-8 md:pt-20 mr-50">
          <img
            className="max-h-[80px]"
            src="https://i.postimg.cc/cHLKWYKY/Logo-Blanco-2.png"
            alt="Logo Starh Company"
          />{" "}
          {/*<p className='py-4'></p>
                <form className='flex flex-col sm:flex-row'>
                    <input className='w-full p-2 mr-4 rounded-md mb-4' type="email" placeholder='Enter email..'/>
                    <button className='p-2 mb-4'>Subscribe</button>
      </form>*/}
        </div>
      </div>

      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">
          © 2024 - Starh Company – Starh Company. Todos los derechos reservados.
        </p>
        <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
          <button className="border-none bg-transparent">
            <FaFacebook />
          </button>

          <button className="border-none bg-transparent">
            <FaInstagram />
          </button>

          <button className="border-none bg-transparent">
            <FaTwitter />
          </button>

          <button className="border-none bg-transparent">
            <FaEnvelope />
          </button>
        </div>
      </div>
      <div>
        <div className="fixed bottom-8 right-8">
          <a href="https://wa.me/526642423977">
            <FaWhatsapp size={70} className="text-green-700" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
