import React from 'react'
import Banner from '../components/Banner';
import Services from '../components/Services';



function Home() {
    return (
        <div>
            <Banner />
            <Services />


        </div>
    );
}

export default Home;
