import React from "react";

const MetrologiaScreen = () => {
  return (
    <div className="max-w-7xl mx-auto bg-gray-100 px-4 py-8 grid lg:grid-cols-3 gap-8 my-32">
      <div className="lg:col-span-2">
        <h3 className="text-3xl font-bold mb-4">SOFTWARE PARA METROLOGIA</h3>
        <p className="text-2xl leading-relaxed text-justify">
          La aplicacion para el analisis dimensional, es fundamentalmente
          necesario para medicion, en la inspeccion de los productos y garantiar
          sus especificaciones. Una solucion unica para el control de calidad.
        </p>
      </div>

      <div className="lg:col-span-1">
        <img
          className="object-cover w-full h-auto"
          src="https://i.postimg.cc/Yqks2hFp/10.png"
          alt="Imagen relacionada"
        />
      </div>
    </div>
  );
};

export default MetrologiaScreen;
