import React from "react";

const Services = () => {
  return (
    <div name="support" className="w-full mt-20">
      <div className="w-full h-[700px] bg-blue-900 absolute">
        <img
          className="w-full h-full object-cover mix-blend-overlay"
          src=""
          alt=""
        />
      </div>

      <div className="max-w-[1240px] mx-auto text-white relative">
        <div className="px-4 py-10">
          {/*<h2 className='text-3xl pt-8 text-slate-300 uppercase text-center'>Support</h2>*/}
          <h3 className="text-5xl font-bold py-5 text-center">
            Nuestros servicios
          </h3>
          <p className="py-2 text-2xl text-slate-300 text-justify">
            El campo abarca desde la medición de piezas con herramientas
            dimensionales hasta el desarrollo de software. Se utiliza tecnología
            como máquinas de medición por coordenadas, escáneres láser 3D,
            máquinas de rayos X y tomografía computarizada para obtener
            mediciones precisas y modelos digitales de muestras. Se emplea la
            técnica de prueba no destructiva para evaluar las propiedades del
            material sin dañarlo, conservando así la integridad del producto.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-5 text-black">
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <h3 className="font-bold text-2xl my-6">
                Instalación y capacitación
              </h3>
              <p className="text-gray-600 text-xl">
                Cada sistema de medición se ofrece con su respectiva
                configuración, arranque e inducción al manejo de sus funciones.
                Con llevando así la obtención de un servicio integral.
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <a
                href="https://wa.me/526642423977"
                className="bg-transparent border-none text-indigo-900"
              >
                Contáctame
              </a>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <h3 className="font-bold text-2xl my-6"> Mantenimiento </h3>
              <p className="text-gray-600 text-xl">
                Con el fin de garantizar el funcionamiento óptimo del sistema
                ofrecido, se realiza actividad de mantenimiento periódico
                preventivo o en su defecto correctivo.
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <a
                href="https://wa.me/526642423977"
                className="bg-transparent border-none text-indigo-900"
              >
                Contáctame
              </a>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <h3 className="font-bold text-2xl my-6"> Calibración </h3>
              <p className="text-gray-600 text-xl">
                Los sistemas de medición se entregan con certificado de
                calibración. Operación bajo condiciones específicas, relación
                entre los valores y sus incertidumbres.
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <a
                href="https://wa.me/526642423977"
                className="bg-transparent border-none text-indigo-900"
              >
                Contáctame
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
