import React from "react";

const SupportScreen = () => {
  return (
    <div className="max-w-[1400px] bg-gray-100 mx-auto md:mb-[10%] px-4 grid lg:grid-cols-3 gap-4 my-32">
      <div className="lg:top-20 relative lg:col-span-1 col-span-2 ">
        <h3 className="text-2xl font-bold">Nosotros</h3>
        <p className="pt-4 text-justify text-2xl">
          Starh Medición y Tecnología cree y confía firmemente en las relaciones
          duraderas que nos permitan alcanzar metas de crecimiento; es por ello
          que cuenta con un equipo profesional, siempre orientado al cliente,
          ofreciendo soluciones de alta tecnología para el control de calidad de
          sus productos. Comprometidos: fue establecida para brindar un servicio
          integral en el instrumental de sus laboratorios para la administración
          de sus procesos.
        </p>
      </div>

      <div className="grid grid-cols-2 col-span-2 gap-2">
        <img
          className="object-cover w-full h-full"
          src="https://i.postimg.cc/BngLTYQ1/3.jpg"
          alt="/"
        />
        <img
          className="row-span-2 object-cover w-full h-full"
          src="https://i.postimg.cc/MpR3rn1S/1.jpg"
          alt="/"
        />

        <img
          className="object-cover w-full h-full"
          src="https://i.postimg.cc/5NDk3BYg/5.jpg"
          alt="/"
        />
      </div>
    </div>
  );
};

export default SupportScreen;
