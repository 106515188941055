import React from "react";
import { FaPhone, FaEnvelope, FaWhatsapp } from "react-icons/fa";

const ContactScreen = () => {
  return (
    <div className="contact w-full my-8 md:my-32 flex flex-col md:flex-row">
      <div className="container max-w-7xl mx-auto overflow-hidden flex flex-col md:flex-row">
        <div className="col-1 flex-1 flex flex-col px-8 py-6 md:py-20">
          <div>
            <h2 className="text-2xl md:text-4xl font-bold mb-4">
              Acerca de la compañía
            </h2>
            <p className="text-justify">
              Somos una empresa comprometida con la industria y las
              instituciones educativas. Nos dedicamos a brindar un servicio
              integral en el instrumental para laboratorios y el control de
              procesos.
            </p>
          </div>
          <img
            className="object-cover w-full h-full mt-4 md:mt-8"
            src="https://i.postimg.cc/15htmZxK/banner.png"
            alt="/"
          />
          <div className="address mt-4 md:mt-8">
            <p>Luis Cabrera 1509,</p>
            <p>Col. Zona Rio. Tijuana Baja California, México.</p>
          </div>
          <div className="icons mt-4 md:mt-8 flex items-center">
            <FaPhone className="h-6 w-6 mr-2" />
            <p>+52 664 656 3749 </p>
          </div>
          <div className="icons mt-2 md:mt-4 flex items-center">
            <FaEnvelope className="h-6 w-6 mr-2" />
            <p>info@starh.com.mx</p>
          </div>
          <div className="icons mt-2 md:mt-4 flex items-center">
            <FaWhatsapp className="h-6 w-6 mr-2" />
            <p>+52 664 24 23 977</p>
          </div>
        </div>
        <div className="col-2 flex-1 flex flex-col px-8 py-6 md:py-20">
          <form action="" className="max-w-xs mx-auto">
            <input
              type="text"
              placeholder="Company"
              className="mb-4 px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-primary-500"
            />
            <input
              type="email"
              placeholder="Email"
              className="mb-4 px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-primary-500"
            />
            <input
              type="tel"
              placeholder="Phone"
              className="mb-4 px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-primary-500"
            />
            <textarea
              name="Message"
              placeholder="Message"
              className="mb-4 px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-primary-500"
            ></textarea>
            <div className="checkbox flex items-center">
              <input type="checkbox" className="mr-2" />
              <p>
                He leído y acepto los{" "}
                <span className="text-blue-500">términos</span> y{" "}
                <span className="text-blue-500">condiciones de uso</span>.
              </p>
            </div>
            <button className="bg-primary-500 text-white px-4 py-2 rounded mt-4">
              Enviar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactScreen;
