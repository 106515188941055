import React from "react";

function ProductsScreen() {
  return (
    <div className="contact w-full my-32 flex justify-center">
      <div className="container max-w-4xl mx-auto overflow-hidden">
        <div className="flex flex-col items-center">
          <img
            className="mb-4"
            src="https://i.postimg.cc/PxnH8NBq/catalogo-Uno.png"
            alt="catalogoUno"
          />
          <img
            className="mb-4"
            src="https://i.postimg.cc/ydhHhsHy/catalogo-Two.png"
            alt="catalogoDos"
          />
          <img
            className="mb-4"
            src="https://i.postimg.cc/yNZBd0p5/catalogo-Three.png"
            alt="catalogoTres"
          />
          <img
            className=""
            src="https://i.postimg.cc/CxtSSc7k/catalogo-Four.png"
            alt="catalogoCuatro"
          />
        </div>
      </div>
    </div>
  );
}

export default ProductsScreen;
