import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./routes/Home";
import ContactScreen from "./routes/ContactScreen";
import SupportScreen from "./routes/SupportScreen"
import ProductsScreen from "./routes/ProductsScreen";
import SoftwareScreen from "./routes/SoftwareScreen";
import MetrologiaScreen from "./routes/MetrologiaScreen";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/support" element={<SupportScreen />} />
        <Route path="/products" element={<ProductsScreen />} />
        <Route path="/software" element={<SoftwareScreen />} />
        <Route path="/metrologia" element={<MetrologiaScreen />} />



      </Routes>
      <Footer />
    </div>
  );
}

export default App;
