import React from "react";

const SoftwareScreen = () => {
  return (
    <div className="max-w-7xl mx-auto bg-gray-100 px-4 py-8 grid lg:grid-cols-3 gap-8 my-32">
      <div className="lg:col-span-2">
        <h3 className="text-3xl font-bold mb-4">
          SOFTWARE PARA INGENIERÍA INVERSA
        </h3>
        <p className="pt-4 text-justify text-2xl">
          La aplicacion para Ingenieria Inversa, ofrece una amplia
          interoperabilidad para los modelos digitalizados. Es una solución
          integral para extraer profesionalmente información de la pieza.
        </p>
      </div>

      <div className="lg:col-span-1">
        <img
          className=""
          src="https://i.postimg.cc/KzfY4QKf/11-1.png"
          alt="/"
        />
      </div>
    </div>
  );
};

export default SoftwareScreen;
