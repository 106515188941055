import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const listRef = useRef();

  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);

  const [showMore, setShowMore] = useState(false);

  // Función para cambiar el estado de showMore
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // Función para cerrar las aplicaciones adicionales cuando se hace clic fuera de la lista
  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setShowMore(false);
    }
  };

  // Agregar un event listener para hacer clic fuera de la lista
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-blue-900 fixed top-0 left-0 right-0 z-50 mx-auto flex justify-between items-center ">
      <div className="mx-auto flex justify-between items-center p-4">
        <div className="flex items-center">
          <a href="/">
            <h1 className="text-3xl font-bold mr-4 sm:text-4xl text-blue-700">
              <img
                className="max-h-[80px]"
                src="https://i.postimg.cc/cHLKWYKY/Logo-Blanco-2.png"
                alt="Logo Starh Company"
              />
            </h1>
          </a>
          <ul className="hidden md:flex">
            <li>
              {/*<Link to="home" smooth={true} duration={500}>*/}
              <Link className="text-white font-bold" to="/" duration={500}>
                Inicio
              </Link>
            </li>
            <li>
              <Link
                className="text-white font-bold"
                to="products"
                offset={-200}
                duration={500}
              >
                Soluciones
              </Link>
            </li>
            <li>
              <Link
                className="text-white font-bold"
                to="/support"
                offset={-50}
                duration={500}
              >
                Nosotros
              </Link>
            </li>
            <button
              className="text-white font-bold bg-transparent border-none"
              onClick={toggleShowMore}
              duration={500}
            >
              Aplicaciones
            </button>
            {showMore && (
              <ul
                ref={listRef}
                className="absolute mt-2 bg-gray-800 py-2 px-4 rounded-lg"
              >
                <li>
                  <Link className="text-white font-bold" to="/software">
                    Diseño y prefabricación de piezas
                  </Link>
                </li>
                <li>
                  <Link className="text-white font-bold" to="/metrologia">
                    Control de calidad e inspección
                  </Link>
                </li>
              </ul>
            )}
            <li>
              <Link
                className="text-white font-bold"
                to="contact"
                offset={-100}
                duration={500}
              >
                Contáctanos
              </Link>
            </li>
          </ul>
        </div>
        {/*
        <div className="hidden md:flex pr-4">
          <button className="border-none bg-transparent text-black mr-4">
            Sign In
          </button>
          <button className="px-8 py-3">Sign Up</button>
        </div>
        */}
        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? (
            <AiOutlineMenu className="w-5" />
          ) : (
            <AiOutlineClose className="w-5" />
          )}
        </div>
      </div>

      <ul
        className={` ${
          !nav
            ? "hidden"
            : "absolute bg-zinc-200 w-50 px-8 mt-80 ml-60 shadow-lg rounded-lg z-50"
        }`}
      >
        <li className="border-b-2 border-zinc-300 w-full">
          {/*<Link onClick={handleClose} to="home" smooth={true} duration={500}>*/}
          <Link onClick={handleClose} to="/" duration={500}>
            Inicio
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="products"
            //smooth={true}
            offset={-200}
            duration={500}
          >
            Soluciones
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="/support"
            //smooth={true}
            offset={-50}
            duration={500}
          >
            Nosotros
          </Link>
        </li>

        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="/contact"
            //smooth={true}
            offset={-100}
            duration={500}
          >
            Contáctanos
          </Link>
        </li>

        {/*
        <div className="flex flex-col my-4">
          <button className="bg-transparent text-indigo-600 px-8 py-3 mb-4">
            Sign In
          </button>
          <button className="px-8 py-3">Sign Up</button>
        </div>
        */}
      </ul>
    </div>
  );
};

export default Navbar;
